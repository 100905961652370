<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <div class="col-12 col-md-8 col-lg-4" v-if="fase === 0" >

                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Olá!</h4>
                        </div>
                        <div class="card-body" >
                            <p>Para começar, por favor, insira o número do seu CPF. Vamos consultar o seu cadastro.</p>

                            <img v-bind:src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Informe o CPF para continuar" />

                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <label for="scpf" >Por favor, informe o seu CPF:</label>
                                    <input v-model="scpf" id="scpf" class="form-control" type="text" placeholder="ex: 000.000.000-00" v-mask="'###.###.###-##'" v-on:input="retrievePatient" v-bind:disabled="token" required autofocus />
                                </div>
                            </div>
                            <div class="form-row" v-if="!token" >
                                <div class="form-group required col-md-12 col-12 mb-0 mt-3" >
                                    <label for="nprest" >Dentista:</label>
                                    <select v-model="nprest" id="nprest" class="form-control" type="text" v-bind:disabled="token || listProvs == '' || existingCPF != true" v-on:change="selectProvider($event)" required >
                                        <option v-for="item in listProvs" v-bind:key="item.ID_PRESTADOR" v-bind:value="item.ID_PRESTADOR" >
                                            {{ item.NM_PRESTADOR }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row" v-if="!token" >
                                <div class="form-group required col-md-12 col-12 mb-0 mt-3" >
                                    <label for="sdoc" >Documento: </label>
                                    <select v-model="sdoc" id="sdoc" class="form-control" type="text" v-bind:disabled="token || listDocs == '' || existingCPF != true" v-on:change="selectDoc($event)" required >
                                        <option v-for="item in listDocs" v-bind:key="item" v-bind:value="item" >
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row" v-if="!token && showBudgets" >
                                <div class="form-group required col-md-12 col-12 mb-0 mt-3" >
                                    <label for="nbudget" >Orçamentos: </label>
                                    <select v-model="nbudget" id="nbudget" class="form-control" type="text" v-bind:disabled="token || listBudgets == '' || existingCPF != true" v-on:change="selectBudget($event)" required >
                                        <option v-for="item in listBudgets" v-bind:key="item.id_orc" v-bind:value="item.id_orc" >
                                            {{ item.nm_orc }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingDoc || consultingCPF" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando o seu cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingCPF == false" >
                                <i class="fas fa-exclamation-circle" ></i> Ops! CPF não encontrado. Verifique o número digitado.
                            </div>
                            <div class="alert alert-warning mt-4 mb-0" v-if="existingDoc == false" >
                                <i class="fas fa-exclamation-circle" ></i> Ops! Ocorreu um erro na leitura dos dados. Por favor retorne e tente novamente.
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingDoc == true && consultingDoc == false" >
                                <i class="fas fa-check-circle" ></i> Tudo certo!
                                Clique em Próximo para continuar.
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingDoc == true" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="avancar" >
                                    Próximo
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingDoc == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="inicio" >
                                    Retornar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <form v-on:submit.prevent="submitForm" >

                <div id="extraslgpd" class="card mt-2rem" v-if="fase === 1" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >{{ sdoc }}</h4>
                    </div>
                    <div class="card-body" >
                        <div class="form-row" >
                            <div class="form-group col-12 mb-0" >
                                <div id="docRtf" >
                                    <div v-html="document" class="m-3" ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="assinatura" class="card mt-2rem" v-if="fase === 1" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Assinatura</h4>
                    </div>
                    <div class="card-body" >
                        <p>Para consentir com o termo acima, por favor, faça sua assinatura na área abaixo:</p>
                        <div class="form-row" >
                            <div class="form-group col-md-12 col-12" >
                                <label for>Assinatura</label>

                                <VueSignaturePad width="100%" height="300px" style="border: 1px solid #f0f0f0" ref="signaturePad" class="signaturePad" />

                                <div class="acoes mt-4" >
                                    <button type="button" class="btn btn-outline-warning" v-on:click="undo" >
                                        <i class="fas fa-eraser" ></i> Apagar
                                    </button>
                                    <button type="button" class="btn btn-success" v-on:click="save" >
                                        Confirmar minha assinatura
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p v-if="signature == ''" >
                            Para consentir com o termo, faça a sua assinatura no quadro acima e clique em "Confirmar minha assinatura".
                        </p>

                        <div class="form-row" >
                            <div class="form-group form-check checkbox mb-3" >
                                <input id="notify" type="checkbox" class="form-check-input" v-model="notify" >
                                <label class="form-check-label" for="notify" >Desejo receber uma cópia por e-mail</label>
                            </div>
                        </div>
                        <div class="form-row" v-if="notify === true" >
                            <div class="form-group required col-12 mb-3" >
                                <input v-model="emailNotify" id="emailNotify" class="form-control" type="email" placeholder="Informe seu e-mail para receber a cópia" />
                            </div>
                        </div>

                        <button type="submit" class="btn btn-success btn-block" v-bind:disabled="saving || signature == ''" >
                            Confirmar
                        </button>

                        <div class="alert mt-3 mb-0" :class="messageStatus" v-if="error == true" >
                            <i class="fas fa-exclamation-circle" ></i>
                            {{ message }}
                        </div>

                        <div class="alert alert-info mt-3 mb-0" v-if="saving" >
                            <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                <span class="sr-only" >Loading...</span>
                            </div>
                            Gravando dados...
                        </div>
                    </div>
                </div>
            </form>

            <div class="row justify-content-center" >
                <div class="col-12 col-lg-4" >
                    <div id="resultado" class="card mt-2rem" v-if="fase === 2" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Obrigado, {{ snome }}!</h4>
                        </div>
                        <div class="card-body" >
                            <div class="alert msgGravacao mt-0" v-bind:class="messageStatus" >
                                {{ message }}
                            </div>

                            <a class="text-decoration-none text-white" v-bind:href="this.linkDocument"
                                target="_blank" rel="noopener noreferrer" >
                                <div class="alert alert-info my-4" v-if="this.linkDocument != null" >
                                    <i class="fas fa-exclamation-circle" ></i>
                                    Clique aqui para acessar documento.
                                </div>
                            </a>

                            <button type="button" class="btn btn-success float-right" v-on:click="inicio" >
                                Retornar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>

</template>

<script>
    import axios  from "axios";
    import moment from "moment";

    export default {
        data()
        {
            return {
                headers       : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                fase          : 0,
                snome         : "",
                ssbrnome      : "",
                scpf          : "",
                nropac        : "",
                nprest        : "",
                nbudget       : 0,
                sdoc          : "",
                signature     : "",
                listProvs     : [],
                listDocs      : [],
                listBudgets   : [],

                showBudgets   : false,

                notify        : null,
                emailNotify   : null,

                consultingDoc : false,
                existingDoc   : null,
                consultingCPF : false,
                existingCPF   : null,
                document      : null,
                linkDocument  : null,

                saving        : null,
                message       : "",
                messageStatus : "",

                countProvds   : 0,
                countDocs     : 0,
                countBudgets  : 0,

                error         : null,
            };
        },

        props:
        {
            user        : Object,
            token       : Boolean,
            clinic      : Object,
            tokenCpf    : String,
            tokenTime   : String,
            resetTokens : Function,
            url         : { type: String, required: true  },
            expAccess   : { type: String, required: false },
        },

        methods:
        {
            inicio()
            {
                this.fase         = 0;
                this.snome        = "";
                this.ssbrnome     = "";
                this.scpf         = "";
                this.nropac       = "";
                this.nprest       = "";
                this.nbudget      = 0;
                this.sdoc         = "";
                this.signature    = "";
                this.existingDoc  = null;
                this.existingCPF  = null;
                this.linkDocument = null;
                this.countProvds  = 0;
                this.countDocs    = 0;
                this.countBudgets = 0;

                this.showBudgets  = false;

                this.notify       = null;
                this.emailNotify  = null;

                this.error        = null;
            },
            avancar()
            {
                this.fase += 1;
            },

            undo()
            {
                this.$refs.signaturePad.clearSignature();
                this.signature = "";
            },
            save()
            {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                if ( !isEmpty )
                {
                    let canvas     = document.querySelector( "canvas" );
                    this.signature = canvas.toDataURL();
                }
                else
                {
                    alert( "A assinatura está em branco." );
                }
            },

            loadProviders()
            {
                this.countProvds += 1;

                axios
                .get( `${this.url}/clinica/prestadores` )
                .then( response =>
                {
                    this.listProvs = response.data;
                } )
                .catch( err =>
                {
                    console.log( `retrieveProviders(), ${err.name}: ${err.message}` );

                    if ( this.countProvds <= 3 )
                        this.loadProviders();
                    else
                        this.existingDoc = false;
                } );
            },
            loadDocs()
            {
                this.countDocs += 1;

                axios
                .get( `${this.url}/clinica/listadocumentos` )
                .then( response =>
                {
                    this.listDocs = ( typeof response.data == "string" ) ? [ response.data ] : response.data;
                } )
                .catch( err =>
                {
                    console.log( `retrieveDocsList(), ${err.name}: ${err.message}` );

                    if ( this.countDocs <= 3 )
                        this.loadDocs();
                    else
                        this.existingDoc = false;
                } );
            },
            loadBudget()
            {
                if ( this.existingCPF != true || this.nprest == "" )
                    return;

                this.countBudgets += 1;

                axios
                .post(
                    `${this.url}/clinica/listaorcamentos`,
                    {
                        nropac : this.nropac,
                        nprest : this.nprest,
                    },
                    this.headers
                )
                .then( response =>
                {
                    let data = response.data.data;

                    if ( data )
                        this.listBudgets = data;
                    else
                        this.retrieveDoc();
                } )
                .catch( err =>
                {
                    console.log( `retrieveBudgetsList(), ${err.name}: ${err.message}` );

                    if ( this.countBudgets <= 3 )
                        this.loadBudget();
                } );
            },

            selectProvider( e )
            {
                this.nprest = e.target.value;

                this.validateVars();
            },
            selectDoc( e )
            {
                this.sdoc = e.target.value;

                this.validateVars();

                if ( this.containsWord( this.sdoc, "Honorários" ) )
                {
                    this.showBudgets = true;
                    this.loadBudget();
                }
                else
                {
                    this.showBudgets = false;
                    this.nbudget     = 0;
                }
            },
            selectBudget( e )
            {
                this.nbudget = e.target.value;

                this.retrieveDoc();
            },

            retrievePatient()
            {
                let treatedCPF = this.scpf.replace( /\D/g, "" );

                if ( this.scpf.length == 14 )
                {
                    this.consultingCPF = true;
                    this.existingCPF   = null;

                    axios
                    .get( `${this.url}/clinica/paciente/${treatedCPF}` )
                    .then( response =>
                    {
                        if ( ( response.data.nropac ) &&
                             !( response.data.sanot.length   === 0 ) &&
                             !( response.data.nconv.length   === 0 ) &&
                             !( response.data.ntpind.length  === 0 ) &&
                             !( response.data.nestciv.length === 0 ) )
                        {
                            this.existingCPF = true;
                            this.nropac      = response.data.nropac;
                            this.snome       = response.data.snome.replace( response.data.ssbrnome, "" ).trimEnd();
                            this.ssbrnome    = response.data.ssbrnome;

                            this.validateVars();
                        }
                        else
                        {
                            this.existingCPF = false;
                        }

                        this.consultingCPF = false;
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.existingCPF   = false;
                        this.consultingCPF = false;
                    } );
                }

                if ( this.scpf.length == 0 )
                {
                    this.existingCPF = null;
                    this.scpf        = "";
                }
            },

            validateVars()
            {
                this.existingDoc   = null;

                const containsWord = this.containsWord( this.sdoc, "Honorários" );

                if ( this.existingCPF == true &&
                     this.nprest      != ''   &&
                     this.sdoc        != ''   &&
                     ( !containsWord || this.nbudget != 0 ) )
                {
                    this.retrieveDoc();
                }
                else if ( containsWord )
                {
                    this.nbudget     = 0;
                    this.listBudgets = [];
                    this.existingDoc = null;
                    this.loadBudget();
                }
            },

            retrieveDoc()
            {
                this.consultingDoc = true;

                const data = {
                    scpf    : this.scpf,
                    nprest  : this.nprest,
                    sdoc    : this.sdoc,
                    nbudget : this.nbudget,
                };

                axios
                .post(
                    `${this.url}/clinica/documento`,
                    data,
                    this.headers
                )
                .then( response =>
                {
                    this.document      = response.data.data;
                    this.existingDoc   = true;
                    this.consultingDoc = false;
                } )
                .catch( err =>
                {
                    console.log( `retrieveDocument(), ${err.name}: ${err.message}` );

                    this.document      = null;
                    this.existingDoc   = false;
                    this.consultingDoc = false;
                } );
            },

            submitForm()
            {
                this.saving = true;

                const data = {
                    docType     : "document",
                    scpf        : this.scpf,
                    sdoc        : this.sdoc,
                    snome       : this.snome,
                    ssbrnome    : this.ssbrnome,
                    nropac      : this.nropac,
                    nprest      : this.nprest,
                    document    : this.document,
                    signature   : this.signature,
                    notify      : this.notify,
                    emailNotify : this.notify ? this.emailNotify : "",
                };

                axios
                .post(
                    `${this.url}/clinica/paciente/documento`,
                    data,
                    this.headers
                )
                .then( response =>
                {
                    this.treatResponseSuccess( response.data );
                } )
                .catch( err =>
                {
                    console.log( `recordDocument(), ${err.name}: ${err.message}` );

                    this.treatResponseError( err.response.data );
                } );
            },

            treatResponseSuccess( response )
            {
                this.linkDocument  = response.data.file;

                this.messageStatus = "alert-success";
                this.message       = "Documento enviado com sucesso.";
                this.saving        = false;
                this.fase          = 2;
            },
            treatResponseError( response )
            {
                if ( response.code == "400SFL" )
                    this.setError();
                else if ( response.exception )
                    this.setError( response.message );
                else
                    this.treatResponseSuccess();
            },
            setError( err )
            {
                const message = ( err )
                    ? "Ops! Aconteceu um erro ao gravar os dados. Contate o suporte."
                    : "Ops! Aconteceu um erro ao gravar os dados. Tente novamente.";

                this.error         = true;
                this.gravando      = false;
                this.message       = message;
                this.messageStatus = "alert-warning";
            },

            containsWord( phrase, word )
            {
                const lowerPhrase = phrase.toLowerCase();
                const lowerWord   = word.toLowerCase();

                return lowerPhrase.includes( lowerWord );
            },
        },

        created()
        {
            if ( this.tokenTime )
            {
                const tkTime = moment( this.tokenTime );
                const now    = moment();
                const diff   = now.diff( tkTime, "seconds" );
                const time   = this.expAccess * 60 * 60;

                // Valida o tempo após o primeiro acesso em segundos
                if ( diff > time )
                    window.location.href = "nao_autorizado/Link expirado!";
            }
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            this.loadProviders();
            this.loadDocs();

            if ( this.tokenCpf )
            {
                this.consultingDoc = true;

                const decd   = this.tokenCpf.split( "||" );

                this.scpf    = decd[ 1 ];
                this.nprest  = decd[ 2 ];
                this.sdoc    = decd[ 3 ];
                this.nbudget = decd[ 4 ] || null;

                this.retrievePatient();
            }
        },
    }
</script>

<style scoped >
    #docRtf {
        background: #e9ecef;
        border-radius: 1.5rem;
        border: 1px solid #cad1d7;
    }
    .checkbox {
        margin-left: .3rem !important;
    }

    .alert-warning-ed {
        color: #857b26;
        background-color: #fffbdb;
        border-color: #fffacc;
    }
</style>

<template>

    <div class="container-fluid" v-if="token == false" >
        <div class="row justify-content-center" >
            <div class="col-12 col-md-8" >

                <div class="card mt-2rem" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Gerar link de contrato</h4>
                    </div>

                    <div class="card-body" >
                        <div class="form-group row mb-0" >
                            <label for="scpf" class="col-md-4 col-form-label text-md-right" >CPF</label>
                            <div class="col-md-6" >
                                <input id="scpf" class="form-control" type="text" placeholder="ex: 000.000.000-00" v-model="scpf" v-mask="'###.###.###-##'" v-on:change="retrievePatient" required autofocus >
                            </div>
                        </div>

                        <div class="form-group row mb-0 mt-3" >
                            <label for="nprest" class="col-md-4 col-form-label text-md-right" >Dentista</label>
                            <div class="col-md-6" >
                                <select v-model="nprest" id="nprest" class="form-control" type="text" v-bind:disabled="listProvs == ''" v-on:change="selectProvider($event)" required >
                                    <option v-for="item in listProvs" v-bind:key="item.ID_PRESTADOR" v-bind:value="item.ID_PRESTADOR" >
                                        {{ item.NM_PRESTADOR }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mb-0 mt-3" >
                            <label for="sdoc" class="col-md-4 col-form-label text-md-right" >Documento</label>
                            <div class="col-md-6" >
                                <select v-model="sdoc" id="sdoc" class="form-control" type="text" v-bind:disabled="listDocs == ''" v-on:change="selectDoc($event)" required >
                                    <option v-for="item in listDocs" v-bind:key="item" v-bind:value="item" >
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mb-0 mt-3" v-if="!token && showBudgets" >
                            <label for="nbudget" class="col-md-4 col-form-label text-md-right" >Orçamentos</label>
                            <div class="col-md-6" >
                                <select v-model="nbudget" id="nbudget" class="form-control" type="text" v-bind:disabled="token || listBudgets == '' || existingCPF != true" v-on:change="selectBudget($event)" required >
                                    <option v-for="item in listBudgets" v-bind:key="item.id_orc" v-bind:value="item.id_orc" >
                                        {{ item.nm_orc }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mb-0" >
                            <div class="col-md-6 offset-md-4" v-if="consultingDoc || consultingCPF" >
                                <div class="alert alert-info mb-0 mt-3" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 offset-md-4" v-if="generatingLink" >
                                <div class="alert alert-info mb-0 mt-3" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Gerando link...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="existingCPF == false || existingDoc == false" key="fail" >
                                <div class="alert alert-warning mb-0 mt-3" >
                                    <i class="fas fa-exclamation-circle" ></i> {{ this.message }}
                                </div>
                                <button class="btn btn-primary btn-block mx-auto mt-3" v-on:click="resetProps" >Limpar</button>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="existingCPF == true && link != ''" key="success" >
                                <div class="alert alert-success mb-0 mt-3" >
                                    <i class="fas fa-check-circle" ></i> Tudo certo! Clique em Copiar para copiar o link.
                                </div>
                                <button class="btn btn-secondary btn-block mx-auto mt-3" v-on:click="resetProps" >Gerar novo link</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" v-if="!generatingLink && link != ''" >
                        <div class="row mb-0" >
                            <div class="col-12 col-md-8 col-lg-9 pr-md-2" >
                                <textarea class="form-control" name="link" rows="7" v-model="link" disabled ></textarea>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 pl-md-2 mt-3 mt-md-0" >
                                <button class="btn btn-primary btn-block" v-clipboard:copy="link" >Copiar</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";

    export default {
        data()
        {
            return {
                headers        : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                generatingLink : false,
                consultingCPF  : false,
                consultingDoc  : false,
                existingCPF    : null,
                existingDoc    : null,
                message        : "",

                link           : "",
                scpf           : "",
                nprest         : "",
                nbudget        : 0,
                sdoc           : "",
                listProvs      : [],
                listDocs       : [],
                listBudgets    : [],

                showBudgets    : false,

                countProvds    : 0,
                countDocs      : 0,
                countBudgets   : 0,
            };
        },
        props:
        {
            url: { type: String, required: true },
            token: { type: Boolean, default: false },
            expAccess: { type: String, required: false },
            expDocument: { type: String, required: false },
			clinic: Object,
        },

        beforeMount()
        {
            if ( this.token )
                window.location.href = "nao_autorizado";
        },

        methods:
        {
            loadProviders()
            {
                this.countProvds += 1;

                axios
                .get( `${this.url}/clinica/prestadores` )
                .then( response =>
                {
                    this.listProvs = response.data;
                } )
                .catch( err =>
                {
                    console.log( `retrieveProviders(), ${err.name}: ${err.message}` );

                    if ( this.countProvds <= 3 )
                    {
                        this.loadProviders();
                    }
                    else
                    {
                        this.existingDoc = false;
                        this.message     = "Ops! Ocorreu um erro na leitura dos dados. Por favor retorne e tente novamente.";
                    }
                } );
            },
            loadDocs()
            {
                this.countDocs += 1;

                axios
                .get( `${this.url}/clinica/listadocumentos` )
                .then( response =>
                {
                    this.listDocs = response.data;
                })
                .catch( err =>
                {
                    console.log( `retrieveDocsList(), ${err.name}: ${err.message}` );

                    if ( this.countDocs <= 3 )
                    {
                        this.loadDocs();
                    }
                    else
                    {
                        this.existingDoc = false;
                        this.message     = "Ops! Ocorreu um erro na leitura dos dados. Por favor retorne e tente novamente.";
                    }
                } );
            },
            loadBudget()
            {
                if ( this.existingCPF != true || this.nprest == "" )
                    return;

                this.countBudgets  += 1;
                this.consultingDoc  = true;

                console.log( {
                    nropac : this.nropac,
                    nprest : this.nprest,
                } );

                axios
                .post(
                    `${this.url}/clinica/listaorcamentos`,
                    {
                        nropac : this.nropac,
                        nprest : this.nprest,
                    },
                    this.headers
                )
                .then( response =>
                {
                    this.consultingDoc = false;

                    if ( response.data.data )
                        this.listBudgets = response.data.data;
                    else
                        this.retrieveDoc();

                } )
                .catch( err =>
                {
                    console.log( `retrieveBudgetsList(), ${err.name}: ${err.message}` );

                    if ( this.countBudgets <= 3 )
                        this.loadBudget();
                } );
            },

            selectProvider( e )
            {
                this.link           = "";

                this.generatingLink = false;
                this.nprest         = e.target.value;

                this.generateLink();
            },
            selectDoc( e )
            {
                this.link           = "";
                this.nbudget        = 0;
                this.showBudgets    = false;
                this.generatingLink = false;
                this.sdoc           = e.target.value;

                this.validateVars();
                this.generateLink();

                if ( this.containsWord( this.sdoc, "Honorários" ) )
                {
                    this.listBudgets = [];
                    this.showBudgets = true;
                    this.loadBudget();
                }
            },
            selectBudget( e )
            {
                this.link    = "";
                this.nbudget = e.target.value;

                this.retrieveDoc();
            },

            retrievePatient()
            {
                this.link      = "";

                let treatedCPF = this.scpf.replace( /\D/g, "" );

                if ( this.scpf.length == 14 )
                {
                    this.consultingCPF = true;
                    this.existingCPF   = null;

                    axios
                    .get( `${this.url}/clinica/paciente/${treatedCPF}` )
                    .then( response =>
                    {
                        if ( ( response.data.nropac ) &&
                             !( response.data.sanot.length   === 0 ) &&
                             !( response.data.nconv.length   === 0 ) &&
                             !( response.data.ntpind.length  === 0 ) &&
                             !( response.data.nestciv.length === 0 ) )
                        {
                            this.consultingCPF = false;
                            this.existingCPF   = true;
                            this.nropac        = response.data.nropac;
                            this.snome         = response.data.snome.replace( response.data.ssbrnome, "" ).trimEnd();
                            this.ssbrnome      = response.data.ssbrnome;

                            this.validateVars();
                            this.generateLink();
                        }
                        else
                        {
                            this.consultingCPF = false;
                            this.existingCPF   = false;
                            this.message       = "CPF não encontrado.";
                        }
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.consultingCPF = false;
                        this.existingCPF   = false;
                        this.message       = "CPF não encontrado.";
                    } );
                }
            },
            retrieveDoc()
            {
                this.link          = "";
                this.consultingDoc = true;

                const data = {
                    scpf    : this.scpf,
                    nprest  : this.nprest,
                    sdoc    : this.sdoc,
                    nbudget : this.nbudget,
                };

                console.log( data );

                axios
                .post(
                    `${this.url}/clinica/documento`,
                    data,
                    this.headers
                )
                .then( response =>
                {
                    this.document      = response.data.data;
                    this.existingDoc   = true;
                    this.consultingDoc = false;

                    this.generateLink();
                } )
                .catch( err =>
                {
                    console.log( `retrieveDocument(), ${err.name}: ${err.message}` );

                    this.document      = null;
                    this.existingDoc   = false;
                    this.consultingDoc = false;
                } );
            },

            validateVars()
            {
                this.link          = "";
                this.existingDoc   = null;

                const containsWord = this.containsWord( this.sdoc, "Honorários" );

                if ( this.existingCPF == true &&
                     this.nprest      != ''   &&
                     this.sdoc        != ''   &&
                     ( !containsWord || this.nbudget != 0 ) )
                {
                    this.retrieveDoc();
                }
                else if ( containsWord )
                {
                    this.nbudget     = 0;
                    this.listBudgets = [];
                    this.existingDoc = null;
                    this.loadBudget();
                }
            },

            generateLink()
            {
                this.link = "";

                if ( this.existingCPF != true || this.nprest == "" || this.sdoc == "" ||
                    ( this.containsWord( this.sdoc, "Honorários" ) && this.nbudget == 0 ) )
                    return;

                this.generatingLink = true;

                const data = {
                    scpf    : this.scpf,
                    nprest  : this.nprest,
                    sdoc    : this.sdoc,
                    nbudget : this.nbudget,
                };

                console.log( data );

                axios
                .post(
                    `${this.url}/gerar/link/documento`,
                    data,
                    this.headers
                )
                .then(response =>
                {
                    this.link            = this.clinic.nome;
                    this.link           += "\n\nClique no link abaixo para acessar o documento.";
                    this.link           += "\n*Atenção! O link é válido por " + this.expAccess + " horas após o primeiro acesso, e validade máxima de " + this.expDocument + " horas.*\n\n";
                    this.link           += response.data.link;

                    console.log( response.data );

                    this.generatingLink  = false;
                })
                .catch( err =>
                {
                    console.log( `linkDocument(), ${err.name}: ${err.message}` );

                    this.link           = "";

                    this.generatingLink = false;
                } );

            },

            resetProps()
            {
                // window.location.href = 'gerar-link-paciente';
                this.consultingCPF = false;
                this.consultingDoc = false;
                this.existingCPF   = null;
                this.existingDoc   = null;
                this.message       = "";
                this.link          = "";
                this.scpf          = "";
                this.nprest        = "";
                this.sdoc          = "";
                this.nbudget       = 0;
                this.countProvds   = 0;
                this.countDocs     = 0;
                this.countBudgets  = 0;
                this.listBudgets   = [];
            },

            containsWord( phrase, word )
            {
                const lowerPhrase = phrase.toLowerCase();
                const lowerWord   = word.toLowerCase();

                return lowerPhrase.includes( lowerWord );
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            this.loadProviders();
            this.loadDocs();
        },
    }
</script>

<style scoped >
</style>
